import './css/Admin.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Row, Col, FormGroup, Label, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { baseUrl, base64Data } from '../shared/baseUrl';

import { GrMultiple, GrDocumentUser } from 'react-icons/gr';
import { SiMicrosoftexcel } from 'react-icons/si';
import { read, write, utils } from 'xlsx';
import ToggleSwitch from './components/toggleswitch';
import ReactPaginate from 'react-paginate';
import { ImCopy } from 'react-icons/im';
import { FiEdit } from "react-icons/fi";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { NavLink } from 'react-router-dom';


import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import DocxMerger from 'docx-merger';

import PizZip from 'pizzip';
import axios from 'axios';
import WebViewer from '@pdftron/webviewer';


function parseDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const options = {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  const formattedDate = dateTime.toLocaleString('fr-FR', options);
  return formattedDate.replace('à', '•');
}



const orderStatus = ['En Production/Non Taillée', 'En Production/Monture Tracée', 'En Production/Clip Fabrication', 'En Production/Monture en Attente', 'En Finition', 'En Production/Monture Reçu', 'Stock / Monture en attente', 'État de compte', 'Expédition', 'Taillage/Montage', 'En production', 'En cours', 'En attente', 'Monture en attente', 'Vérification en cours']
const OrderViewComponent = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [generatedFile, setGeneratedFile] = useState();
  const [modal, setModal] = useState(false);
  const [modalEditAlert, setModalEditAlert] = useState(false);

  const viewerRef = useRef(null)

  const callView = async () => {
    WebViewer(
        {
          licenseKey : 'demo:k.ashrf911@gmail.com:7e71b4220200000000330d47a06d805e15c0d2fd796b09c85c9359c34a',
          path: 'lib',
          initialDoc: generatedFile,
          extension: 'docx',
          isReadOnly: true
        },
        viewerRef.current,
    ).then((instance) => {
      // Disable editing
      instance.UI.disableElements([
        'annotationPopup',
        'annotationNoteConnectorLine',
        'annotationNote',
        'annotationStylePopup',
        'textPopup',
        'contextMenuPopup',
        'textSelectButton',
        'panToolButton',
        'linkButton',
        'highlightToolGroupButton',
        'annotationToolsButton',
        'toolsHeader',
        'toolbarGroup-Annotate',
        'toolbarGroup-Edit',
        'toolsButton',
        'menuButton',
        'rubberStampToolGroupButton',
        'signatureToolButton',
        'stampToolButton',
        'stickyToolButton',
        'richTextPopup',
        'textUnderlineToolButton',
        'textStrikeoutToolButton',
        'freeTextToolButton',
        'stickyNoteToolButton',
        'contextMenuPopup',
        'marqueeToolButton'
      ]);
    });
  }

  const loadLocalFile = async() => {
    const file = await fetch('OLAB_Bon_de_COMMANDE.docx');
    const buffer = await file.arrayBuffer();
    return buffer;
  }

  function getObjectValue(obj, path) {
    const parts = path.split('.');
    let value = obj;

    for (const part of parts) {
      value = value[part];
      if (value === undefined) {
        return '';
      }
    }


    if((parts.includes('ADD') || parts.includes('CYL') || parts.includes('SPH')) ){
      value = parseFloat(value).toFixed(2);
      if(value > 0) {
        value = '+ ' + value;
      }
    }
    return value;
  }

  async function generateFile(order) {
    const content = await loadLocalFile();
    const InspectModule = require("docxtemplater/js/inspect-module");
    const iModule = InspectModule();
    var zip = new PizZip(content);
    var doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      modules: [iModule]
    });
    const tags = iModule.getAllTags();

    for (const key in tags) {
      tags[key] = getObjectValue(order, key);
    }

    if (order?.product?.rx?.OD == "Ne pas commander" || order?.product?.rx?.OS == "Ne pas commander") {
      if (Object.keys(tags).includes('product.lens.design.OS')) {
        tags['product.lens.design.OS'] = ''
      }
    }

    if (Object.keys(tags).includes('expedition')) {
      tags['expedition'] = new Date(order?.expedition).toLocaleDateString('en-CA');
    }
    if (Object.keys(tags).includes('created')) {
      tags['created'] = new Date(order?.created).toLocaleDateString('en-CA');
    }
    tags['detailedprice.design'] = parseFloat(tags['detailedprice.design'] ).toFixed(2);
    tags['detailedprice.designbis'] = parseFloat(tags['detailedprice.designbis'] ).toFixed(2);
    tags['detailedprice.frameType'] = parseFloat(tags['detailedprice.frameType'] ).toFixed(2);
    tags['detailedprice.tailler'] = parseFloat(tags['detailedprice.tailler'] ).toFixed(2);
    tags['detailedprice.curve'] = parseFloat(tags['detailedprice.curve'] ).toFixed(2);
    tags['detailedprice.shade'] = parseFloat(tags['detailedprice.shade'] ).toFixed(2);
    tags['detailedprice.prisme'] = parseFloat(tags['detailedprice.prisme'] ).toFixed(2);
    tags['detailedprice.mirror'] = parseFloat(tags['detailedprice.mirror'] ).toFixed(2);
    tags['detailedprice.manutention'] = parseFloat(tags['detailedprice.manutention'] ).toFixed(2);
    tags['detailedprice.extraOption'] = parseFloat(tags['detailedprice.extraOption'] ).toFixed(2);

    console.log(tags)
    doc.setData(tags);

    try {
      doc.render();
    } catch (error) {

      console.error('Error rendering document:', error);
      return;
    }

    var out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    // Create a URL for the generated file and set it to state
    const blobUrl = URL.createObjectURL(out);

    setGeneratedFile(blobUrl)
  }

  const toggle = async (order) =>{
    const test = await generateFile(order);
     setModal(!modal)
   };

   const toggleEditAlert = () => {
    setModalEditAlert(!modalEditAlert)
   }

  // const [search, setSearch] = useState(null);
  const fetchOrders = async (params) => {
    try {
      const bearer = 'Bearer ' + localStorage.getItem('token');
      const pagingInfo = (params?.pagingInfo) ?  params?.pagingInfo.selected + 1 :  1;
      const keyword = (params?.keyword) ?  params?.keyword :  '';
      const filters = {
        search : keyword,
        pageIndex: pagingInfo,
        limit: 10
      }
      const response = await axios.get(baseUrl + "order/orders",{
        headers : {
          'Content-Type': 'application/json',
          'Authorization': bearer
        },
        params: filters
      });
      let data = await response.data.docs;
      setOrders(data);
      setFilteredOrders(data)
      setTotalPageCount(response.data.totalPages);
    } catch (error) {
      console.log("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [])
  useEffect(() => {
    try {
      console.log('TESTO');

      // if (search?.length > 2 && orders?.length > 0) {
      //   const search_words = search.toLowerCase().match(/[a-zA-Z0-9]+/g)
      //   const filteredData = orders.filter((item) => {
      //     return search_words.every(w => JSON.stringify(Object.values(item)).toLowerCase().includes(w))
      //   })
      //   setFilteredOrders(filteredData)
      // } else {
      //   setFilteredOrders(orders)
      // }
      //setFilteredOrders(orders);
    }
    catch (e) {
      //setFilteredOrders(orders)
    }
  }, [orders])
  const handleRowClick = (row) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };





  return (
    <div className='adminContainer'>
      <Modal isOpen={modal} toggle={toggle} fullscreen onOpened={() => callView()}>
        <ModalHeader toggle={toggle}>Facture</ModalHeader>
        <ModalBody id='modal_body'>
          <div className={'previewDoc'} ref={viewerRef} style={{ width: '100%', height: '100vh' }}></div>
          {/* <div dangerouslySetInnerHTML={{__html: generatedHtmlFile}}></div> */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEditAlert} toggle={toggleEditAlert}>
        <ModalHeader toggle={toggleEditAlert}>Modification Info</ModalHeader>
        <ModalBody id='modal_body'>
          <p>Veuillez noter que votre commande est maintenant en production et malheureusement, vous n’êtes plus en mesure d’effectuer une modification de celle-ci. Veuillez nous contacter au numéro 450-466-2862/1-800-761-2862 pour toutes questions.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEditAlert}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
      <div className='adminHeader'>Liste des commandes</div>
      <div className='adminSearch'>
        <Input
          type="text"
          className='adminSearchInput'
          placeholder="Recherche"
          onChange={(e) => {
            fetchOrders({keyword: e.target.value});
          }}

        />
      </div>

      <div className='orderListContainer-admin-history'>
        <div className='orders-pagination'>
            <ReactPaginate className="react-paginate"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={(pagingInfo) => {setPageIndex(pagingInfo?.selected === 0 ? 1 : pagingInfo?.selected)
                  return fetchOrders({pagingInfo})}}
                pageRangeDisplayed={5}
                pageCount={totalPageCount}
                forcePage={pageIndex}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
        </div>
        <div className='orderList-admin orderList-admin-history'>
        <Table striped responsive>
          <thead>
            <tr>
              <th>
                ID
              </th>
              <th>
                Numéro de facture
              </th>
              <th>
                Création
              </th>
              <th>
                Status
              </th>
              <th>
                Expédition
              </th>
              <th>
                Patient
              </th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
          {filteredOrders.map((ele, index) => (
            <tr key={ele._id}>
              <td>
                {ele._id}
              </td>
              <td>
                {ele.uniqueKey}
              </td>
              <td>
                {parseDateTime(ele.created)}
              </td>
              <td>
                {ele.status}
              </td>
              <td >
                {(ele.expedition) ? parseDateTime(ele.expedition) : 'Pas Expédié'}
              </td>
              <td>
                {(ele.product?.rx?.name) ? ele.product?.rx?.name : 'null'}
              </td>
              <td>
                <NavLink to="/commande" state={{orderId: ele._id , originPage : 'historique'}}>
                  <ImCopy size='20' color='#694708'/>
                </NavLink>
                &nbsp;
                &nbsp;
                {ele.status === 'En cours' ?
                <span>
                <NavLink to="/commande" state={{orderId: ele._id , edit : true , originPage : 'historique'}}>
                  <FiEdit size='20' color='#694708'/>
                </NavLink>
              </span>
              :
              <span onClick={toggleEditAlert}>
                    <FiEdit size='20' color='#694708'/>
                </span>
              }
                &nbsp;
                &nbsp;
                <NavLink onClick={() => toggle(ele)}>
                  <HiOutlineDocumentSearch size='20' color='#694708'/>
                </NavLink>
              </td>
              </tr>
          ))}
          </tbody>
        </Table>
          {/* <div className="headrow-orderList-admin" >
            <Col xs="2" className="head-column-border">
              ID
            </Col>
            <Col xs="2" className="head-column-border">
              Création
            </Col>
            <Col xs="2" className="head-column-border" >
              Status
            </Col>
            <Col xs="2" className="head-column-border">
              Expédition
            </Col>
            <Col xs="2" className="head-column-border">
              Patient
            </Col>
          </div>
          <div className="" >
            <Col xs="2" className="head-column-border">
              ID
            </Col>
          </div> */}
          {/* {filteredOrders.map((ele, index) => (
            <div className="row-orderList-admin" key={ele._id}>
              <Col xs="2" className="column-border" style={{ fontWeight: '600', fontStyle: 'italic' }}>
                {ele._id}
              </Col>
              <Col xs="2" className="column-border" style={{ fontWeight: '500', color: '#434343', fontStyle: 'italic', color: '#694708' }}>
                {parseDateTime(ele.created)}
              </Col>
              <Col xs="2" className="column-border" style={{ fontWeight: '600', color: '#694708' }}>
                {ele.status}
              </Col>
              <Col xs="2" className="column-border" style={{ ffontWeight: '500', color: '#434343', fontStyle: 'italic', color: '#694708' }}>
                {(ele.expedition) ? parseDateTime(ele.expedition) : 'Pas Expédié'}
              </Col>
              <Col xs="2" className="column-border" style={{ fontWeight: '400' }}>
                {(ele.product?.rx?.name) ? ele.product?.rx?.name : 'null'}
              </Col>
            </div>
          ))} */}
        </div>
      </div>
    </div >
  );
};

export default OrderViewComponent;
